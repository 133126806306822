<template>
  <div style="flex: 1">
    <div
      ref="a"
      :style="{height: height, width: width}"
    ></div>
    <div style="font-size: 12px; top: -30px; position: relative">
      {{ title }}
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
require('echarts/theme/macarons'); // echarts theme
import {debounce} from '@/utils';

export default {
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      chart: null,
      height: '200px',
      width: '100%',
    };
  },
  mounted() {
    this.initChart();
    this.__resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener('resize', this.__resizeHandler);
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener('resize', this.__resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.a, 'macarons');

      var option = {
        series: [
          {
            name: 'Pie Chart 1',
            type: 'pie',
            radius: '50%',
            data: this.data,
            label: {
              formatter: '{c}',
              color: 'white',
              position: 'inside',
            },
          },
        ],
        legend: {},
      };

      this.chart.setOption(option);
    },
  },
};
</script>
